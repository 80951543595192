<template>
  <section>
    <runai-table-wrapper :filters-object="filterBy" sticky>
      <template v-slot:actions>
        <runai-page-actions
          primary-btn-label="new project"
          :filters="filterBy"
          :columns="columns"
          :selected-rows-amount="selectedRowsAmount"
          @filters-changed="updateFilters"
          @create-entity="createProject"
          @selected-actions-close="resetSelectedRows"
          @export-csv="exportTableAsCsv"
          advanced-filters
        >
          <template v-slot:selected-rows-actions>
            <runai-action-button
              v-permission="{ resourceType: ResourceType.AccessRules, action: Action.Read }"
              btn-action="assign"
              @click="openAccessRuleModal"
            />
            <runai-action-button
              v-permission="{ resourceType: ResourceType.Project, action: Action.Update }"
              aid="edit-project-btn"
              btn-action="edit"
              @click="editProject"
            />
            <runai-tooltip-wrapper
              :display-tooltip="disablePolicyButton"
              tooltip-text="There is no policy applied for the selected project"
            >
              <runai-action-button
                v-if="showPolicyButton"
                v-permission="{ resourceType: ResourceType.Project, action: Action.Read }"
                aid="view-project-policy-btn"
                btn-action="viewPolicy"
                :disable="disablePolicyButton"
              >
                <policy-dropdown
                  @policy-selected="viewPolicy"
                  entity-name="project"
                  :existing-policies="policyMenuOptions"
                />
              </runai-action-button>
            </runai-tooltip-wrapper>
            <runai-action-button
              v-permission="{ resourceType: ResourceType.Project, action: Action.Delete }"
              aid="delete-project-btn"
              btn-action="delete"
              @click="openDeleteModal"
            />
          </template>
        </runai-page-actions>
      </template>

      <template v-slot:table>
        <runai-table
          :columns="columns"
          :rows="projectsTableData"
          :loading="loadingTableData"
          :filter-by="filterBy"
          v-model:selected="selectedProjects"
          @update-filters="updateFilters"
          @node-pools-clicked="displayProjectNodePools"
          @access-rules-clicked="displayAccessRuleTableModal"
          @workloads-clicked="displayWorkloadsModal"
          @avg-gpu-utilization-timeframe-changed="onAvgGpuUtilizationTimeframeChanged"
          @avg-gpu-allocation-timeframe-changed="onAvgGpuAllocationTimeframeChanged"
          :get-row-key="getRowKey"
          :top-row="lastCreatedProject"
          sticky-columns
          is-server-side-pagination
          :rows-per-page-options="[1, 5, 7, 10, 15, 20, 25, 50, 100]"
          :bordered="false"
          :override-table-header="showOrgUnitOverTimeData"
        >
          <template #no-data>
            <runai-table-no-data
              v-if="!loadingTableData && !lastCreatedProject"
              :filter-by="filterBy"
              entity-name="project"
              :icon-name="projectNoDataIcon"
              icon-size="122px"
              @clear-filters="clearFilters"
              @create-new="createProject"
              :show-create-btn="canCreateProjects"
              :show-error="loadingError"
              :custom-message="getCustomMessage"
              ignore-cluster-filter
            >
              <template v-if="!canCreateProjects" v-slot:secondary-text>
                To be assigned to a project contact your administrator.</template
              >
            </runai-table-no-data>
          </template>
        </runai-table>
      </template>
    </runai-table-wrapper>
    <node-pools-modal v-if="isNodePoolsModalOpen" :modal-options="nodePoolsModalOptions" @close="closeNodePoolsModal" />
    <access-rule-table-modal
      v-if="isAccessRuleTableModalOpen"
      @close="closeAccessRuleTableModal"
      :modal-options="accessRuleTableModalOptions"
    />
    <project-delete-modal
      v-if="isDeleteModalOpen && selectedProject"
      :project-name="selectedProject.name"
      :namespace="selectedProject.status?.namespace"
      @delete="onProjectDelete"
      @close="isDeleteModalOpen = false"
      :loading="isDeleting"
    />
    <access-rule-management-modal
      v-if="isAccessRuleManagementModalOpen"
      @close="isAccessRuleManagementModalOpen = false"
      @access-rule-created="onAccessRuleCreated"
      @access-rule-deleted="onAccessRuleDeleted"
      :modal-options="accessRuleManagementModalOptions"
    />
    <workload-list-modal
      v-if="isWorkloadsModalOpen"
      :modal-options="workloadsModalOptions"
      @close="isWorkloadsModalOpen = false"
    />
  </section>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
//store
import { useSettingStore } from "@/stores/setting.store";

//models
import {
  EColumnFilterType,
  EFilterOperator,
  type IFilterBy,
  type IFreeTextFilterModel,
  type IPaginationFilter,
} from "@/models/filter.model";
import { type INodePoolModalOptions, type IProjectTable, type IProjectTableFilterBy } from "@/models/project.model";
import { projectDependentColumns, projectIndexColumns } from "@/table-models/project.table-model";
import { ETableFilters, type ITableColumn } from "@/models/table.model";
import { ETableExportCsvFilesNames } from "@/models/table.model";

//constants
import { alertUtil } from "@/utils/alert.util";
import { tableUtil } from "@/utils/table.util";

import { PROJECT_ROUTE_NAMES } from "@/router/project.routes/project.routes.names";
//components
import { NodePoolsModal } from "@/components/node-pools/node-pools-modal";
import { RunaiTableWrapper } from "@/components/common/runai-table-wrapper";
import { RunaiPageActions } from "@/components/common/runai-page-actions";
import { RunaiActionButton } from "@/components/common/runai-page-actions/runai-action-button";
import { ProjectDeleteModal } from "@/components/project/project-delete-modal";
import { RunaiTable } from "@/components/common/runai-table";
import { RunaiTableNoData } from "@/components/common/runai-table/runai-table-no-data";
import { PolicyDropdown } from "@/components/policy/policy-dropdown";
import { WorkloadListModal } from "@/components/workload/workload-list-modal";

import type { IAccessRuleManagementModalOptions, IAccessRuleTableModalOptions } from "@/models/access-rule.model";
import { EAccessRuleModalPage } from "@/models/access-rule.model";
import { accessRulesMiniTableColumns } from "@/table-models/access-rule.table-model";
import { AccessRuleManagementModal } from "@/components/rbac/access-rule/access-rule-management-modal";
import { type AccessRule, Action, ResourceType, ScopeType } from "@/swagger-models/authorization-client";
import { accessRuleService } from "@/services/control-plane/rbac/access-rule.service/access-rule.service";
import { useClusterStore } from "@/stores/cluster.store";

import { EQuotaEntity } from "@/models/resource.model";
import {
  PolicyType,
  type TrainingPolicyV2,
  type TrainingPolicyDefaultsAndRulesV2Defaults,
  type TrainingPolicyDefaultsAndRulesV2Rules,
  type WorkspacePolicyV2,
  type WorkspacePolicyDefaultsAndRulesV2Defaults,
  type WorkspacePolicyDefaultsAndRulesV2Rules,
  type DistributedPolicyV2,
  type DistributedPolicyDefaultsV2,
  type DistributedPolicyRulesV2,
  InferencePolicyDefaultsAndRulesV2Defaults,
  InferencePolicyDefaultsAndRulesV2Rules,
  InferencePolicyV2,
} from "@/swagger-models/policy-service-client";
import { AccessRuleTableModal } from "@/components/rbac/access-rule/access-rule-table-modal/";
import { usePermissionStore } from "@/stores/permissions.store";
import { useNodePoolStore } from "@/stores/node-pool.store";
import { POLICIES_ROUTE_NAMES } from "@/router/policy.routes/policy.routes.names";
import { policyUtil, type IPolicyScopeProperties } from "@/utils/policy.util/policy.util";
import { EWorkloadModalEntity, type IWorkloadListModalOptions } from "@/models/workload.model";
import { RunaiTooltipWrapper } from "@/components/common/runai-tooltip-wrapper";
import { WorkloadSortFilterFields } from "@/swagger-models/workloads-service-client";
import { workloadListModalColumns } from "@/table-models/workload.table-model";
import type { IPolicyMap } from "@/models/policy.model";
import { policyService } from "@/services/control-plane/policy.service/policy.service";
import { EAdvancedIndexPages } from "@/composables/helpers/use-table-data.mapper";
import { useAppStore } from "@/stores/app.store";
import { useAuthStore } from "@/stores/auth.store";
import { enrichScopeEntityWithAccessRules } from "@/utils/rbac.util/access-rule.util/access-rule.util";
import { useTableDataAdvancedFilters } from "@/composables/use-table-data-advanced-filters.composable/use-table-data-advanced-filters.composable";
import { orgUnitService } from "@/services/control-plane/org-unit.service/org-unit.service";
import { filterService } from "@/services/filter.service/filter.service";
import { ProjectFilterSortFields } from "@/swagger-models/org-unit-service-client";
import { useRoute } from "vue-router";
import { orgUnitUtil } from "@/utils/org-unit.util";
import { unleashService } from "@/services/infra/unleash.service/unleash.service";
import { EOrgUnitOverTimeColumnName, EOrgUnitOverTimeValue } from "@/models/org-unit.model";

const DEFAULT_WORKLOADS_MODAL_OPTIONS: IWorkloadListModalOptions = {
  entityFilter: "",
  entityName: "",
  filterName: WorkloadSortFilterFields.ProjectName,
  entityType: EWorkloadModalEntity.Project,
  columns: workloadListModalColumns,
  clusterId: "",
};

export default defineComponent({
  name: "projects-index",
  components: {
    RunaiTooltipWrapper,
    WorkloadListModal,
    AccessRuleTableModal,
    AccessRuleManagementModal,
    RunaiTableNoData,
    RunaiTable,
    ProjectDeleteModal,
    NodePoolsModal,
    RunaiPageActions,
    RunaiTableWrapper,
    RunaiActionButton,
    PolicyDropdown,
  },
  data() {
    return {
      appStore: useAppStore(),
      isDeleting: false as boolean,
      isDeleteModalOpen: false as boolean,
      isNodePoolsModalOpen: false as boolean,
      isAccessRuleTableModalOpen: false as boolean,
      isAccessRuleManagementModalOpen: false as boolean,
      isWorkloadsModalOpen: false as boolean,
      selectedProjects: [] as Array<IProjectTable>,
      accessRuleTableModalOptions: {
        accessRules: [],
        header: "",
        columns: accessRulesMiniTableColumns,
        loading: false,
      } as IAccessRuleTableModalOptions,
      nodePoolsModalOptions: {
        resources: [],
        nodePoolQuotaStatuses: [],
        entityType: EQuotaEntity.project,
        header: "",
      } as INodePoolModalOptions,
      accessRuleManagementModalOptions: {
        page: EAccessRuleModalPage.Project,
        scopeType: ScopeType.Project,
        scopeName: "",
        scopeId: "",
      } as IAccessRuleManagementModalOptions,
      currentTimeoutId: 0 as number,
      loadingPolicies: false as boolean,
      selectedProjectPolicies: null as IPolicyMap | null,
      workloadsModalOptions: {
        ...DEFAULT_WORKLOADS_MODAL_OPTIONS,
      } as IWorkloadListModalOptions,
      loadedPoliciesMapByProjectId: {} as Record<string, IPolicyMap>,
    };
  },
  setup() {
    const settingStore = useSettingStore();
    const clusterStore = useClusterStore();
    const permissionStore = usePermissionStore();
    const nodePoolStore = useNodePoolStore();
    const route = useRoute();

    const isOnlyDefaultNodePool = ref(false);
    const clusterId = ref(clusterStore.getClusterIdFromFilters(ETableFilters.PROJECT, true, true));

    const showOrgUnitOverTimeData = unleashService.showOrgUnitOverTimeData();

    const columns = computed<ITableColumn[]>(() => {
      return projectIndexColumns.filter((col: ITableColumn) => {
        if (projectDependentColumns.cpu.has(col.name)) {
          return settingStore.isCPUResourcesQuotaEnabled;
        }
        if (projectDependentColumns.department.has(col.name)) {
          return settingStore.isDepartmentEnabled;
        }
        if (projectDependentColumns.accessRules.has(col.name)) {
          return permissionStore.hasPermission(ResourceType.AccessRules, Action.Read);
        }
        if (projectDependentColumns.nodePools.has(col.name)) {
          return !isOnlyDefaultNodePool.value;
        }
        if (projectDependentColumns.overTimeData.has(col.name)) {
          return showOrgUnitOverTimeData;
        }
        return true;
      });
    });

    const canReadProjects = computed(() => permissionStore.hasPermission(ResourceType.Project, Action.Read));

    const checkIfOnlyDefaultNodePool = async (): Promise<void> => {
      //we want to check if there is only default node pool only if we not all clusters selected, RUN-11773 will fix this.
      if (!clusterId.value) return;
      isOnlyDefaultNodePool.value = await nodePoolStore.isOnlyDefaultNodePoolByClusterId(clusterId.value);
    };

    const getProjects = async (filterBy: IProjectTableFilterBy): Promise<IProjectTable[]> => {
      return orgUnitService.listProjects(filterBy);
    };

    const getProject = async (id: string): Promise<IProjectTable> => {
      return (await orgUnitService.getProject(id)) as IProjectTable;
    };

    const countProjects = async (): Promise<void> => {
      const filters: IPaginationFilter = orgUnitUtil.getProjectListFilters(filterBy.value as IProjectTableFilterBy);
      const count = await orgUnitService.countProjects(filters.filterBy || []);
      updateFilterByKey("rowsNumber", count);
    };

    const getProjectsWithAdditionalData = async (filterBy: IProjectTableFilterBy): Promise<IProjectTable[]> => {
      const projects = await getProjects(filterBy);
      try {
        if (canReadProjects.value) {
          const scopeAccessRules = await accessRuleService.getAccessRules({
            scopeType: ScopeType.Project,
          });

          return enrichScopeEntityWithAccessRules(projects, scopeAccessRules) as IProjectTable[];
        }
      } catch (e) {
        console.error("Failed to enrich projects with additional data", e);
      }
      return projects as IProjectTable[];
    };

    const setProjectNameFilter = (): void => {
      const filteredClusterId = route.query[ProjectFilterSortFields.ClusterId] as string;
      const projectName = route.query[ProjectFilterSortFields.Name] as string;
      const projectNameFilter: IFreeTextFilterModel = {
        name: ProjectFilterSortFields.Name,
        term: projectName,
        value: EFilterOperator.Equals,
        label: "Name",
        type: EColumnFilterType.FreeText,
      };

      filterBy.value.clusterId = filteredClusterId;
      clusterId.value = filteredClusterId;
      filterService.setColumnAdvancedFilter(filterBy.value, projectNameFilter, ETableFilters.PROJECT);
    };

    const initCustomColumnsHeader = () => {
      if (!(filterBy.value as IProjectTableFilterBy).avgGpuUtilizationTimeframe) {
        (filterBy.value as IProjectTableFilterBy).avgGpuUtilizationTimeframe = EOrgUnitOverTimeValue.TwentyFourHours;
      }
      if (!(filterBy.value as IProjectTableFilterBy).avgGpuAllocationTimeframe) {
        (filterBy.value as IProjectTableFilterBy).avgGpuAllocationTimeframe = EOrgUnitOverTimeValue.TwentyFourHours;
      }
    };

    const onAvgGpuUtilizationTimeframeChanged = (timeframe: string) => {
      updateFilterByKey("avgGpuUtilizationTimeframe" as keyof IFilterBy, timeframe);
      const shouldRefresh = filterBy.value?.advancedFilters?.some(
        (filter) => filter.name === EOrgUnitOverTimeColumnName.AverageGpuUtilization,
      );
      if (shouldRefresh) {
        refreshList();
      }
    };

    const onAvgGpuAllocationTimeframeChanged = (timeframe: string) => {
      updateFilterByKey("avgGpuAllocationTimeframe" as keyof IFilterBy, timeframe);
      const shouldRefresh = filterBy.value?.advancedFilters?.some(
        (filter) => filter.name === EOrgUnitOverTimeColumnName.AverageGpuAllocation,
      );
      if (shouldRefresh) {
        refreshList();
      }
    };

    const {
      setFilterBy,
      clearFilters,
      refreshList,
      tableData,
      filterBy,
      loadingTableData,
      loadingError,
      lastCreatedEntity,
      clearLastCreated,
      initTableFilter,
      updateCellContent,
      removeRow,
      updateFilterByKey,
    } = useTableDataAdvancedFilters<IProjectTable>(
      getProjects,
      columns,
      EAdvancedIndexPages.PROJECT,
      getProject,
      getProjectsWithAdditionalData,
    );
    if (route.query[ProjectFilterSortFields.Name]) {
      setProjectNameFilter();
    }
    initTableFilter();

    initCustomColumnsHeader();

    checkIfOnlyDefaultNodePool();
    countProjects();

    return {
      lastCreatedEntity,
      clearLastCreated,
      setFilterBy,
      updateFilterByKey,
      clearFilters,
      refreshList,
      tableData,
      filterBy,
      loadingTableData,
      loadingError,
      columns,
      updateCellContent,
      removeRow,
      settingStore,
      clusterStore,
      permissionStore,
      nodePoolStore,
      clusterId,
      countProjects,
      checkIfOnlyDefaultNodePool,
      showOrgUnitOverTimeData,
      onAvgGpuUtilizationTimeframeChanged,
      onAvgGpuAllocationTimeframeChanged,
    };
  },
  created() {
    this.appStore.setPageLoading(false);
  },
  computed: {
    Action(): typeof Action {
      return Action;
    },
    ResourceType(): typeof ResourceType {
      return ResourceType;
    },
    selectedRowsAmount(): number {
      return this.selectedProjects.length;
    },
    canCreateProjects(): boolean {
      return this.permissionStore.hasPermission(ResourceType.Project, Action.Create);
    },
    selectedProject(): IProjectTable | null {
      if (this.selectedProjects.length > 0) {
        return this.selectedProjects[0];
      }
      return null;
    },
    projectsTableData(): IProjectTable[] {
      const gpuUtilizationTimeframe = (this.filterBy as IProjectTableFilterBy)?.avgGpuUtilizationTimeframe;
      const gpuAllocationTimeframe = (this.filterBy as IProjectTableFilterBy)?.avgGpuAllocationTimeframe;
      const getAverageGpuUtilization = (project: IProjectTable) =>
        project.overtimeData &&
        orgUnitUtil.getAverageGpuUtilizationDataByTimeframe(project.overtimeData, gpuUtilizationTimeframe);

      const getAverageGpuAllocation = (project: IProjectTable) =>
        project.overtimeData &&
        orgUnitUtil.getAverageGpuAllocationDataByTimeframe(project.overtimeData, gpuAllocationTimeframe);

      return this.tableData.map((project: IProjectTable) => {
        return {
          ...project,
          averageGpuUtilization: getAverageGpuUtilization(project),
          averageGpuAllocation: getAverageGpuAllocation(project),
        };
      });
    },
    getCustomMessage(): string {
      if (this.canCreateProjects) {
        return "You don't have any projects yet.";
      }
      return "You are not assigned to any project yet.";
    },
    disablePolicyButton(): boolean {
      return this.loadingPolicies || !this.policyMenuOptions.length;
    },
    hasInteractivePolicy(): boolean {
      if (!this.selectedProjectPolicies) return false;
      const rules: WorkspacePolicyDefaultsAndRulesV2Rules =
        this.selectedProjectPolicies[PolicyType.Workspace]?.effective?.rules || {};
      const defaults: WorkspacePolicyDefaultsAndRulesV2Defaults =
        this.selectedProjectPolicies[PolicyType.Workspace]?.effective?.defaults || {};
      return !!Object.keys(rules).length || !!Object.keys(defaults).length;
    },
    hasTrainingPolicy(): boolean {
      if (!this.selectedProjectPolicies) return false;
      const rules: TrainingPolicyDefaultsAndRulesV2Rules =
        this.selectedProjectPolicies[PolicyType.Training]?.effective?.rules || {};
      const defaults: TrainingPolicyDefaultsAndRulesV2Defaults =
        this.selectedProjectPolicies[PolicyType.Training]?.effective?.defaults || {};
      return !!Object.keys(rules).length || !!Object.keys(defaults).length;
    },
    hasDistributedPolicy(): boolean {
      if (!this.selectedProjectPolicies) return false;
      const rules: DistributedPolicyRulesV2 =
        this.selectedProjectPolicies[PolicyType.Distributed]?.effective?.rules || {};
      const defaults: DistributedPolicyDefaultsV2 =
        this.selectedProjectPolicies[PolicyType.Distributed]?.effective?.defaults || {};
      return !!Object.keys(rules).length || !!Object.keys(defaults).length;
    },
    hasInferencePolicy(): boolean {
      if (!this.selectedProjectPolicies) return false;
      const rules: InferencePolicyDefaultsAndRulesV2Rules =
        this.selectedProjectPolicies[PolicyType.Inference]?.effective?.rules || {};
      const defaults: InferencePolicyDefaultsAndRulesV2Defaults =
        this.selectedProjectPolicies[PolicyType.Inference]?.effective?.defaults || {};
      return !!Object.keys(rules).length || !!Object.keys(defaults).length;
    },
    policyMenuOptions(): Array<PolicyType> {
      const policyOptions: Array<PolicyType> = [];
      if (!this.selectedProjectPolicies) return policyOptions;

      this.hasInteractivePolicy && policyOptions.push(PolicyType.Workspace);
      this.hasTrainingPolicy && policyOptions.push(PolicyType.Training);
      this.hasDistributedPolicy && policyOptions.push(PolicyType.Distributed);

      return policyOptions;
    },
    showPolicyButton(): boolean {
      return useSettingStore().isPolicyManagerEnabled;
    },
    lastCreatedProject(): IProjectTable | null {
      return this.lastCreatedEntity;
    },
    projectNoDataIcon(): string {
      return this.appStore.isNewNavigationFeatureOn ? "project-new" : "project";
    },
  },
  methods: {
    updateFilters(filters: IProjectTableFilterBy): void {
      this.clusterId = filters.clusterId || ""; // "" is for all clusters
      this.setFilterBy(filters);
      this.countProjects();
      this.checkIfOnlyDefaultNodePool();
    },
    async loadSelectedProjectPolicy(): Promise<void> {
      if (!this.selectedProject) {
        this.selectedProjectPolicies = null;
        return;
      }
      const alreadyLoaded = this.loadedPoliciesMapByProjectId[this.selectedProject.id];
      if (alreadyLoaded) {
        this.selectedProjectPolicies = alreadyLoaded;
        return;
      }

      try {
        this.loadingPolicies = true;
        const scopeProperties: IPolicyScopeProperties = { projectId: this.selectedProject.id, scope: ScopeType.Project };
        await Promise.all([
          policyService.loadPolicyByMetaAndType(PolicyType.Workspace, scopeProperties),
          policyService.loadPolicyByMetaAndType(PolicyType.Training, scopeProperties),
          policyService.loadPolicyByMetaAndType(PolicyType.Distributed, scopeProperties),
          policyService.loadPolicyByMetaAndType(PolicyType.Inference, scopeProperties),
        ]).then((res) => {
          this.selectedProjectPolicies = {
            [PolicyType.Workspace]: res[0] as WorkspacePolicyV2,
            [PolicyType.Training]: res[1] as TrainingPolicyV2,
            [PolicyType.Distributed]: res[2] as DistributedPolicyV2,
            [PolicyType.Inference]: res[3] as InferencePolicyV2,
          };
          this.selectedProject?.id &&
            (this.loadedPoliciesMapByProjectId[this.selectedProject.id] = this.selectedProjectPolicies);
        });
      } catch (err: unknown) {
        console.error("Failed to load project policies", err);
      } finally {
        this.loadingPolicies = false;
      }
    },
    viewPolicy(workloadType: PolicyType): void {
      if (!this.selectedProject?.id) return;
      const policyId = policyUtil.createPolicyId(workloadType, ScopeType.Project, this.selectedProject.id);
      this.$router.push({
        name: POLICIES_ROUTE_NAMES.POLICY_VIEW,
        params: { id: policyId },
        query: { previousRoute: PROJECT_ROUTE_NAMES.PROJECT_INDEX },
      });
    },
    resetSelectedRows(): void {
      this.selectedProjects = [];
    },
    createProject(): void {
      this.$router.push({ name: PROJECT_ROUTE_NAMES.PROJECT_NEW });
    },
    editProject(): void {
      if (!this.selectedProject) return;
      this.$router.push({
        name: PROJECT_ROUTE_NAMES.PROJECT_EDIT,
        params: { id: this.selectedProject.id },
        query: {
          clusterId: this.selectedProject.clusterId,
        },
      });
    },
    openDeleteModal(): void {
      this.isDeleteModalOpen = true;
    },
    async onProjectDelete(): Promise<void> {
      if (!this.selectedProject) return;
      this.isDeleting = true;
      try {
        await orgUnitService.deleteProject(this.selectedProject.id);
        this.removeRow(this.selectedProject);

        this.isDeleteModalOpen = false;
        await useAuthStore().loadUserOrgUnits();

        this.$q.notify(alertUtil.getSuccess(`Project ${this.selectedProject.name} deleted`));
      } catch (e) {
        console.error(e);
        this.$q.notify(alertUtil.getError(`Project ${this.selectedProject.name} couldn't be deleted`));
      } finally {
        this.resetSelectedRows();
        this.isDeleting = false;
      }
    },
    displayProjectNodePools(project: IProjectTable): void {
      this.isNodePoolsModalOpen = true;
      this.nodePoolsModalOptions.header = `Node Pools Associated with Project ${project.name}`;
      this.nodePoolsModalOptions.resources = project.resources;
      this.nodePoolsModalOptions.nodePoolQuotaStatuses = project.status?.nodePoolQuotaStatuses || [];
      this.nodePoolsModalOptions.defaultNodePools = project.effective?.defaultNodePools || [];
    },
    async displayAccessRuleTableModal(project: IProjectTable): Promise<void> {
      this.isAccessRuleTableModalOpen = true;
      this.accessRuleTableModalOptions.loading = true;
      this.accessRuleTableModalOptions.header = `Subjects Authorized for Project ${project.name}`;
      const accessRulesRecords = await accessRuleService.getAccessRules({
        scopeType: ScopeType.Project,
        scopeId: project.id.toString(),
      });
      this.accessRuleTableModalOptions.accessRules = accessRulesRecords.accessRules;
      this.accessRuleTableModalOptions.loading = false;
    },
    closeAccessRuleTableModal(): void {
      this.isAccessRuleTableModalOpen = false;
      this.accessRuleTableModalOptions.accessRules = [];
      this.accessRuleTableModalOptions.header = "";
    },
    closeNodePoolsModal(): void {
      this.isNodePoolsModalOpen = false;
      this.resetNodePoolsModalOptions();
    },
    resetNodePoolsModalOptions(): void {
      this.nodePoolsModalOptions = {
        resources: [],
        entityType: EQuotaEntity.project,
        header: "",
        nodePoolQuotaStatuses: [],
        defaultNodePools: [],
      };
    },
    getRowKey(row: IProjectTable): string {
      return row.id;
    },

    openAccessRuleModal(): void {
      if (!this.selectedProject) return;
      this.accessRuleManagementModalOptions.scopeName = this.selectedProject?.name;
      this.accessRuleManagementModalOptions.scopeId = this.selectedProject.id.toString();
      this.isAccessRuleManagementModalOpen = true;
    },
    onAccessRuleCreated(accessRule: AccessRule): void {
      if (!this.selectedProject?.id) return;
      const roles = this.tableData.find((project: IProjectTable) => project.id === this.selectedProject?.id)?.rolesNames;
      this.updateCellContent(this.selectedProject, "rolesNames", [accessRule.subjectType, ...(roles || [])]);
    },
    onAccessRuleDeleted(accessRuleIndex: number): void {
      if (!this.selectedProject?.id) return;
      const roles = this.tableData.find((project: IProjectTable) => project.id === this.selectedProject?.id)?.rolesNames;
      const rolesWithoutDeleted = roles?.filter((role: string, index: number) => index !== accessRuleIndex);
      this.updateCellContent(this.selectedProject, "rolesNames", rolesWithoutDeleted);
    },
    exportTableAsCsv(): void {
      const columns = this.columns.filter((col: ITableColumn) => this.filterBy.displayedColumns?.includes(col.name));

      tableUtil.exportTableAsCsv(ETableExportCsvFilesNames.Project, this.tableData, columns);
    },
    async displayWorkloadsModal(project: IProjectTable): Promise<void> {
      this.isWorkloadsModalOpen = true;
      this.workloadsModalOptions.clusterId = project.clusterId;
      this.workloadsModalOptions.entityFilter = `${WorkloadSortFilterFields.ProjectId}${EFilterOperator.Equals}${project.id}`;
      this.workloadsModalOptions.entityName = project.name;
    },
    resetWorkloadsModalOptions(): void {
      this.workloadsModalOptions = { ...DEFAULT_WORKLOADS_MODAL_OPTIONS };
    },
  },
  watch: {
    selectedProject: {
      handler(newVal: IProjectTable | null): void {
        if (!newVal) return;
        this.loadSelectedProjectPolicy();
      },
    },
    isWorkloadsModalOpen(isOpen: boolean): void {
      if (!isOpen) {
        this.resetWorkloadsModalOptions();
      }
    },
  },
});
</script>

<style scoped></style>
